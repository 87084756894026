<template>
  <select class="form-control" :class="{ 'is-invalid': hasError }" :id="inputId" :aria-describedby="helpTextId" :multiple="true" v-model="selection">
    <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
  </select>
</template>

<script>
import inputMixin from './inputMixin'

export default {
  mixins: [
    inputMixin
  ],
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      selection: []
    }
  },
  created () {
    if (this.value && this.value.length) {
      this.selection = this.value
    }
  },
  watch: {
    selection (val) {
      this.$emit('value-change', val)
    }
  }
}
</script>
